import React from 'react';
import Link from '@/components/Link';

export default function Science({ handleSignUp }: any) {
  return (
    <section
      id="om"
      className="min-h-screen bg-white relative pb-[32px] md:pb-[24px] lg:pb-[164px] pt-[32px] md:pt-[24px] lg:pt-[20px] mt-[138px] xl:mt-[146px]"
    >
      <div className="max-w-[1180px] mx-auto px-8 xl:px-0">
        <legend className="font-bold md:text-[96px] text-4xl text-[#484352] md:leading-[88.7px]">
          How the <br />
          Science works
        </legend>

        <div className="flex w-full max-h-[301px] h-full mt-[16px] md:mt-[24px] xl:mt-[64px] md:gap-x-[20px]">
          <div
          // className="max-w-[780px] h-full w-full overflow-hidden object-fill"
          >
            <img
              src="/images/om-1.svg"
              alt="Vitract | How it works"
              className="max-w-[780px] md:h-[200px] slg:h-[250px] xl:h-full w-full overflow-hidden object-fill"
            />
          </div>
          <div
          // className="md:block hidden max-w-[380px] h-full w-full overflow-hidden object-fill"
          >
            <img
              src="/images/om-2.svg"
              alt="Vitract | How it works"
              className="md:block hidden max-w-[380px] md:h-[200px] slg:h-[250px] xl:h-full w-full overflow-hidden object-fill"
            />
          </div>
        </div>

        <div className="mt-[16px] md:mt-[24px] xl:mt-[64px]">
          <p className="text-[#8C8C8C] md:text-[32px] md:leading-[39.68px]">
            In the last couple of years, there has been overwhelming evidence
            that the human gut plays a crucial role in overall health and
            wellness. This spans from impacting digestive function to immunity,
            food metabolism, and even mental health. Interestingly, these events
            are modulated by the trillions of gut microorganisms, the
            functionality of their genes, and their respective gene expression
            profiles. From numerous scientific literature, it is well understood
            that the relative composition and diversity of gut microbes, coupled
            with the various biochemical pathways that they modulate, drive or
            contribute to chronic diseases, including digestive health problems
            (such as irritable bowel disease, constipation, bloating, etc.),
            metabolic diseases (e.g., obesity, type II diabetes, cardiovascular
            diseases), immunity, brain health problems (e.g., brain fog,
            insomnia, stress), and mental disorders (e.g., Alzheimer's disease,
            depression, etc.).
          </p>
          <p className="mt-[34px] md:mt-[68px] text-[#8C8C8C] md:text-[32px] md:leading-[39.68px]">
            To gain deeper insight into the underlying factors of these health
            conditions, it is reasonable to understand how the nature of one’s
            gut ecosystem may be contributing to the symptoms being experienced.
            Therefore, identifying the various biomarkers of one’s gut health is
            crucial.
          </p>
        </div>

        <div className="flex flex-col-reverse md:flex-row items-center mt-[16px] md:mt-[24px] lg:mt-[100px] md:max-w-[1106px] mx-auto">
          <img
            src="/images/om-3.svg"
            alt="Vitract"
            className="w-[250px] md:w-[300px] lg:w-[400px] h-fit pt-[16px] md:pt-[50px] lg:pt-auto"
            // width="400px"
            // height="404px"

            data-aos="fade-left"
            data-aos-easing="ease-in-sine"
          />
          <div
            className="max-w-[561px] sm:ml-[113px] md:ml-[50px] xl:ml-auto"
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
          >
            <h3 className="text-[20px] md:text-[38px] text-center md:text-left font-bold text-gray-dark leading-tight">
              We ship to the United States and Canada
              <br className="hidden md:block" />
            </h3>
            {/* <p className="mt-[10px] md:mt-[18px] min-w-[300px] md:w-full text-center md:text-left text-[12px] md:text-lg text-gray-lighter font-thin">
             This service is available to people in the US and Canada.
            </p> */}
            <form
              onSubmit={handleSignUp}
              className="grid md:grid-cols-2 mt-[20px] md:mt-[20px] gap-y-[10px] md:gap-x-[20px]  "
            >
              <Link
                text="Recieve Free Guide Now"
                href="/downloads/free-wellness-guide.pdf"
                className="text-[14px] col-span-2 w-fit text-white bg-primary px-[20px] py-[10px] rounded-[4px] mt-[10px] text-center mx-auto md:ml-0 hover:bg-navy-blue hover:shadow-2xl hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out"
              />
            </form>
          </div>
        </div>

        <div className="mt-[16px] md:mt-[24px] lg:mt-[100px]">
          <p className="text-[#8C8C8C] md:text-[32px] md:leading-[39.68px]">
            At Vitract, we use cutting-edge molecular techniques to take a
            snapshot of your gut, scouring for microbial groups that play key
            roles in your overall gut health. The data derived from this
            analysis allows us to gain insights into various indicators of your
            gut health such as intestinal permeability, butyrate production,
            tryptophan metabolism, vitamin production, LPS production, local and
            systemic inflammation, insulin resistance, etc., all of which are
            underlying factors of symptoms of several chronic illnesses.
          </p>
          <p className="mt-[34px] md:mt-[68px] text-[#8C8C8C] md:text-[32px] md:leading-[39.68px]">
            We understand that nothing impacts the gut microbiome more than the
            food that we eat, implying that your gut health can be improved by
            eating the food that is uniquely right for you. Therefore, our
            technology has been armed with artificial intelligence tools that
            extract thousands of useful information from well-vetted academic
            literatures, allowing us to generate personalized recommendations
            (namely food, prebiotics, probiotics) to engineer and promote your
            gut health. Our solution is very rooted in science, and we are using
            technology to bring to life decades of scientific findings buried in
            literature.
          </p>
        </div>
      </div>
    </section>
  );
}
